import { UserRoles } from '@/store/enums/StoreEnums';

const DocMenuConfig = [
  {
    allowed: [UserRoles.THIRD_PARTY],
    heading: 'Dashboard',
    pages: [
      {
        heading: 'Overview',
        route: '/dashboard',
        allowed: [UserRoles.THIRD_PARTY],
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Sales Report',
        route: '/sales-report',
        allowed: [UserRoles.THIRD_PARTY],
        svgIcon: 'media/icons/custom/aside/sales.svg',
      },
      // {
      //   sectionTitle: 'Refund List',
      //   svgIcon: 'media/icons/duotune/arrows/arr015.svg',
      //   sub: [
      //     {
      //       heading: 'QR Payment',
      //       route: '/refund-list?paymentMethod=QR',
      //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
      //       svgIcon: 'media/icons/duotune/arrows/arr015.svg',
      //     },
      //     {
      //       heading: 'Credit Card',
      //       route: '/refund-list?paymentMethod=Credit',
      //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
      //       svgIcon: 'media/icons/duotune/arrows/arr015.svg',
      //     },
      //   ],
      // },
      // {
      //   heading: 'Store Report',
      //   route: '/store-report',
      //   allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
      //   svgIcon: 'media/icons/custom/aside/user-report.svg',
      // },
    ],
  },
  //
  // {
  //   allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //   heading: 'Management',
  //   pages: [
  //     {
  //       sectionTitle: 'Order',
  //       svgIcon: 'media/icons/duotune/ecommerce/ecm001.svg',
  //       sub: [
  //         {
  //           heading: 'Overview',
  //           route: '/order/overview',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Pending',
  //           route: '/order/pending',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Accepted',
  //           route: '/order/accepted',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'On Service',
  //           route: '/order/on-service',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'returned',
  //           route: '/order/returned',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Completed',
  //           route: '/order/completed',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Canceled',
  //           route: '/order/canceled',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //       ],
  //     },
  //     {
  //       sectionTitle: 'Customer Account',
  //       svgIcon: 'media/icons/custom/aside/user.svg',
  //       sub: [
  //         {
  //           heading: 'Account Lists',
  //           route: '/customer-account/lists',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Account Verification',
  //           route: '/customer-account/verify',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //       ],
  //     },
  //     {
  //       sectionTitle: 'Partner Account',
  //       svgIcon: 'media/icons/duotune/communication/com006.svg',
  //       sub: [
  //         {
  //           heading: 'Account Lists',
  //           route: '/partner-account/lists',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Account Verification',
  //           route: '/partner-account/verify',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //       ],
  //     },
  //     {
  //       heading: 'Commission Setting',
  //       svgIcon: 'media/icons/custom/aside/user.svg',
  //       route: '/packages',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       heading: 'Partner Store',
  //       svgIcon: 'media/icons/custom/aside/user.svg',
  //       route: '/stores',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       sectionTitle: 'Partner Car',
  //       svgIcon: 'media/icons/custom/aside/car.svg',
  //       sub: [
  //         {
  //           heading: 'Partner Car Lists',
  //           route: '/partner-car/lists',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Partner Car Verification',
  //           route: '/partner-car/verify',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //       ],
  //     },
  //     {
  //       sectionTitle: 'Car & Function',
  //       svgIcon: 'media/icons/custom/aside/settings.svg',
  //       sub: [
  //         {
  //           heading: 'Car List',
  //           route: '/car-function/car-lists',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           heading: 'Function List',
  //           route: '/car-function/function-lists',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //         },
  //         {
  //           sectionTitle: 'Car Management',
  //           route: '/car-function/car-management',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //           sub: [
  //             {
  //               heading: 'Add Car',
  //               route: '/car-function/car-management/add-car',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Type',
  //               route: '/car-function/car-management/manage-type',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Tag',
  //               route: '/car-function/car-management/manage-tag',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Brand',
  //               route: '/car-function/car-management/manage-brand',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Model',
  //               route: '/car-function/car-management/manage-model',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Sub Model',
  //               route: '/car-function/car-management/manage-sub-model',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Manage Color',
  //               route: '/car-function/car-management/manage-color',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //           ],
  //         },
  //         {
  //           sectionTitle: 'Import From Excel',
  //           route: '/import-excel',
  //           allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //           sub: [
  //             {
  //               heading: 'Import Car',
  //               route: '/import-excel/car',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //             {
  //               heading: 'Import Function',
  //               route: '/import-excel/function',
  //               allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       heading: 'Car Node',
  //       svgIcon: 'media/icons/duotune/general/gen018.svg',
  //       route: '/location/node-list',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       heading: 'Refunding',
  //       svgIcon: 'media/icons/duotune/general/gen018.svg',
  //       route: '/order/cancel-list',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       heading: 'Promotion',
  //       svgIcon: 'media/icons/duotune/coding/cod001.svg',
  //       route: '/promotions',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       heading: 'Promotion Banners',
  //       svgIcon: 'media/icons/duotune/coding/cod001.svg',
  //       route: '/promotion-banners',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //     {
  //       heading: 'Third Party',
  //       svgIcon: 'media/icons/duotune/coding/cod001.svg',
  //       route: '/third-party',
  //       allowed: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
  //     },
  //   ],
  // },
  // {
  //   allowed: [UserRoles.SUPER_ADMIN],
  //   heading: 'Apps',
  //   pages: [
  //     {
  //       heading: 'User Management',
  //       svgIcon: 'media/icons/duotune/general/gen049.svg',
  //       route: '/user-management',
  //       allowed: [UserRoles.SUPER_ADMIN],
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
