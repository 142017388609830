
import IconPallette from '@/components/icon/IconPallette.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n/index';
import { useRoute } from 'vue-router';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import MainMenuConfig from '@/core/config/MainMenuConfig';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'kt-menu',
  components: {
    IconPallette,
  },
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      currentUser: computed(() => {
        return store.getters.currentUser;
      }),
    };
  },
});
